@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");

@keyframes pulseGlow {
  from {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 255, 0.7),
      inset 0px 0px 10px 2px rgba(255, 255, 0, 0.8);
  }
  to {
    box-shadow: 0px 0px 25px 10px rgba(0, 0, 255, 1),
      inset 0px 0px 15px 5px rgba(255, 255, 0, 1);
  }
}

.typing-game h2 {
  font-size: 32px;
  margin-bottom: 20px;
  color: #ffdd57;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.9);
}

.typing-game pre {
  background-color: rgba(0, 0, 0, 0.9);
  padding: 20px;
  border-radius: 10px;
  font-size: 20px;
  color: #00ff6a;
  font-family: "Courier New", Courier, monospace;
  white-space: pre-wrap;
  word-wrap: break-word;
  border: 2px solid #007bff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.6);
  max-height: 200px;
  overflow-y: auto;
}

.typing-game .input-field {
  width: 100%;
  padding: 15px;
  margin-top: 15px;
  font-size: 20px;
  border: 2px solid #ffdd57;
  border-radius: 10px;
  background-color: #222;
  color: #fff;
  outline: none;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}

.typing-game .input-field:focus {
  background-color: #333;
  border-color: #ff5500;
}

.typing-game .timer-typing {
  padding-top: 10px;
  animation: pulseTimer 1s infinite ease-in-out;
  margin-bottom: 20px;
}

@keyframes pulseTimer {
  0% {
    color: #ffdd57;
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.game-over-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 1.5s ease-out;
}

.game-over-header {
  animation: bounceIn 0.5s ease-out forwards;
}

@keyframes bounceIn {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.game-over-score {
  font-size: 24px;
  margin-bottom: 20px;
  color: #fff;
}

.FourPic-game-over .reset {
  margin-left: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.error {
  color: red;
  font-weight: bold;
  text-decoration: underline;
}
