@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

.dashboard-container {
  max-width: 100%;
  height: 100vh;
  background-color: #c9d6ff;
  background: linear-gradient(to right, #e2e2e2, #c9d6ff);
}

.profile-image {
  margin-left: 20px;
}

.header-container {
  display: flex;
  width: 100%;
  height: 70px;
  background-color: #08152c;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0 20px;
  position: relative;
}

.header-container h3 {
  font-size: 20px;
  font-weight: 700;
  color: #c0cfdd;
  margin: 0;
  margin-left: 20px;
  font-family: "Montserrat", sans-serif;
}

.header-container h3 {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.header-container .links {
  display: flex;
  gap: 30px;
  margin-left: auto;
  margin-right: auto;
}

.header-container a {
  font-style: normal;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  color: #ccd5df;
  transition: color 0.3s ease, font-size 0.3s ease;
  transition: color 0.3s ease;
}

.header-container a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #32c0ca;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.header-container::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  bottom: 0;
  left: 0;
}

.header-container a:hover::after {
  transform: scaleX(1);
}

.header-container a:hover {
  color: #32c0ca;
  font-size: 16px;
  font-weight: 650;
}

.header-container a.active {
  color: #32c0ca;
  font-size: 16px;
  font-weight: 650;
  border-bottom: 2px solid #32c0ca;
}

.header-container img {
  width: auto;
  height: 40px;
  transition: transform 0.3s ease;
}

.header-container img:hover {
  transform: scale(1.1);
}

p {
  color: white;
}

.header-container {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header-container {
  animation: slideIn 0.5s ease-out;
}

.header-container button:hover {
  background: linear-gradient(45deg, #530fff, #3700b3);
  color: #fff;
  border: 1px solid transparent;
  transform: scale(1.1) rotate(0deg);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.8);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.carousel-container {
  max-width: 60%;
  margin-top: 3rem;
  margin-left: 15.5rem;
  margin-top: 5rem;
}

.carousel-container img {
  width: 500px;
  height: 500px;
}

@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }

  .header-container .links {
    flex-direction: column;
    gap: 10px;
    margin: 0;
  }

  .header-container button {
    width: 100%;
  }
}

.profile-container {
  display: flex;
  align-items: center;
}

.username {
  margin: 0;
  font-size: 16px;
  color: #3fdbe0;
  font-weight: 700px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5), 0 0 25px rgba(63, 219, 224, 0.8),
    0 0 5px rgba(63, 219, 224, 0.8);
  animation: text-glow-multi 5s ease-in-out infinite alternate;
  transition: transform 0.2s;
}

.username:hover {
  transform: scale(1.05);
}

@keyframes dash-animation {
  0% {
    stroke-dasharray: 0, 188.4;
  }
  50% {
    stroke-dasharray: 94.2, 188.4;
  }
  100% {
    stroke-dasharray: 188.4, 188.4;
  }
}

@keyframes glow-animation {
  0% {
    stroke: #ff6f61;
    filter: drop-shadow(0 0 10px #ff6f61);
  }
  100% {
    stroke: #ffab91;
    filter: drop-shadow(0 0 20px #ffab91);
  }
}

@keyframes text-pulse {
  0% {
    fill: #ff6f61;
  }
  100% {
    fill: #ffffff;
  }
}

.sign-in-out {
  background-color: #f9f9f9;
  color: #333;
  border-radius: 8px;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease, color 0.3s ease;
  font-size: 15px;
  width: 80px;
  text-align: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  transform: translateY(0);
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: block;
}

.sign-in-out:hover {
  color: #333;
  background-color: #333;
  font-size: 15px;
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.sign-in-out:active {
  transform: translateY(0);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.dropdown {
  position: absolute;
  background-color: transparent;
  border: 1px solid #2f9a9e;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 110px;
  border-radius: 10px;
  font-size: 12px;
  background-color: #fff;
  padding: 5px;
  z-index: 9999;
}

.dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown ul li {
  padding: 5px;
  cursor: pointer;
}

.dropdown ul li:hover {
  background-color: #f0f0f0;
  border-radius: 10px;
}

.dropdown a {
  font-size: 14px;
  font-weight: normal;
  color: #08152c;
}

.dropdown a:hover {
  font-size: 14px;
  font-weight: normal;
}

.user-account {
  border: none;
  border-radius: 10px;
  padding: 2px;
  font-size: 12px;
  background-color: #fff;
}

.sign-out-btn {
  border: none;
  border-radius: 10px;
  padding: 2px;
  font-size: 12px;
  margin-top: 10px;
  background-color: #fff;
}

.user-account:hover {
  background-color: none;
}

.disclaimer {
  position: fixed;
  top: 75px;
  right: 20px;
  background-color: rgba(39, 79, 97, 0.9);
  color: #fff;
  padding: 15px 25px;
  border-radius: 8px;
  font-size: 14px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.5s ease forwards;
  z-index: 1000;
  display: flex;
  align-items: center;
  border: 1px solid #2bb6af;
  gap: 10px;
  font-family: Arial, sans-serif;
  width: 500px;
}

.close-disc {
  background: none;
  border: none;
  color: #ff4d4d;
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  cursor: pointer;
  transition: color 0.3s ease;
  position: absolute;
  margin-left: 450px;
  margin-bottom: 35px;
}

.close-disc:hover {
  color: #b30000;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
  90% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}
