@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap&font-display=swap");

.quiz-container {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  position: relative;
  background: linear-gradient(135deg, #0f1a36, #1c2b47);
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  position: relative;
  gap: 50px;
}

.quizes-container .type-btn{
  display: flex;
  justify-content: center;
  position: relative;
  left: 50%;
}

.quiz-four-pic-btn{
  display: flex;
  justify-content: center;
  position: relative;
  left: 50%;
}

html,
body {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.particles {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 1;
  overflow: hidden;
}

@keyframes floatParticles {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  100% {
    transform: translateY(-300px) rotate(360deg);
  }
}

.particle {
  width: 7px;
  height: 7px;
  background: rgba(9, 165, 165, 0.15);
  border-radius: 50%;
  position: absolute;
  top: 100%;
  animation: floatParticles 10s infinite;
}

.quizes-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 700px;
  width: 100%;
  z-index: 10;
  align-items: center;
}

.quizes-container div {
  width: 100%;
  height: 120px;
  background: linear-gradient(145deg, #1c2b47, #2e3e5c);
  padding: 20px;
  border-radius: 15px;
  font-size: 1.2rem;
  font-family: "Press Start 2P", cursive;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease,
    background-color 0.3s ease, filter 0.3s ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: 3px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Press Start 2P", cursive;
  will-change: transform, box-shadow;
}

.quizes-container div:hover {
  transform: scale(1.08) rotate(3deg);
  background: linear-gradient(145deg, #2e3e5c, #3e506d);
  filter: drop-shadow(0 0 10px #ffdd57);
  border-color: #ffdd57;
  box-shadow: 0 15px 30px rgba(255, 203, 5, 0.4);
  text-shadow: 2px 2px #ffdd57;
}

@media (min-width: 1400px) {
  .quizes-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

.quizes-container div:focus {
  outline: none;
  border-color: #ffdd57;
  box-shadow: 0 0 0 3px rgba(255, 221, 87, 0.75);
}

@media (max-width: 768px) {
  .quizes-container {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .quizes-container div {
    font-size: 1.2rem;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .quizes-container {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .quizes-container div {
    font-size: 1rem;
    padding: 10px;
  }
}

@media (min-width: 1200px) {
  .quiz-container {
    min-height: 89vh;
  }

  .quizes-container {
    max-width: 800px;
  }
}

.quiz-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-width: 100%;
  max-height: 100vh;
  color: #ffffff;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.5);
  border: 1px solid #ffdd57;
  animation: modalShow 0.6s ease-out forwards;
  z-index: 1000;
  background: rgba(28, 43, 71, 0.1);
}

@keyframes modalShow {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.8) rotate(-10deg);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1) rotate(0deg);
  }
}

.modal-header {
  font-size: 1.5rem;
  text-align: center;
  color: #ffdd57;
  text-shadow: 2px 2px #ff6b6b, 1px 1px #ffdd57;
  margin-bottom: 15px;
}

.modal-debug {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-height: 80vh;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background-color: #ff6b6b;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  border-radius: 50%;
  padding: 5px 10px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.close-button:hover {
  background-color: #e63946;
  transform: scale(1.1);
}

.mute-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: transparent;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.quiz-container.blurred .quizes-container,
.quiz-container.blurred .particles,
.quiz-container.blurred .mute-button {
  filter: blur(4px);
  pointer-events: none;
}

.quiz-container.blurred::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.tips-list {
  list-style-type: none;
  font-size: 20px;
  padding: 0;
  margin: 0;
}

.tips-list li {
  margin-bottom: 8px;
}

.tips-section p{
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.start-quiz-button {
  border: none;
  border-radius: 10px;
  width: 100px;
  background: linear-gradient(90deg, #ffdd57, #ffbb33); 
  color: #333; 
  font-size: 16px; 
  font-weight: bold; 
  padding: 10px; 
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}

.start-quiz-button:hover {
  background: linear-gradient(90deg, #ffbb33, #ffdd57); 
  transform: translateY(-2px); 
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); 
}

.start-quiz-button:active {
  transform: translateY(0); 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


