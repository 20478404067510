.spinner {
    border: 6px solid rgba(255, 255, 255, 0.1);
    border-top: 6px solid #ffffff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .stat-container{
    height: calc(100vh - 50px); 
    overflow-y: auto;
  }