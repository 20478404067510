.question-container {
    text-align: center;
  }
  
  .question-title {
    font-size: 2rem;
    margin-bottom: 20px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
    animation: fadeIn 1s ease-out;
  }
  
  .input-answer {
    background: #ffffff;
    border: 2px solid #f77f00;
    padding: 10px;
    margin-top: 20px;
    border-radius: 8px;
    font-size: 1.2rem;
    width: 80%;
    max-width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition:
      border-color 0.3s ease,
      box-shadow 0.3s ease;
  }
  
  .input-answer:focus {
    border-color: #d62839;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
    outline: none;
    color: black;
  }
  
  .submit-button {
    background: #f77f00;
    border: none;
    color: #ffffff;
    padding: 15px 30px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1.2rem;
    transition:
      background 0.3s ease,
      transform 0.3s ease;
    margin-left: 20px;
    margin-top: 20px;
  }
  
  .submit-button:hover {
    background: #d62839;
    transform: scale(1.05);
  }
  
  .results {
    text-align: center;
    background: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    max-height: calc(100vh - 80px);
    overflow-y: auto;
  }
  
  .results ul {
    list-style: none;
    padding: 0;
  }
  
  .results li {
    margin: 10px 0;
    padding: 10px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
  }
  
  .correct {
    color: #a8d5e2;
    font-weight: bold;
  }
  
  .incorrect {
    color: #ff6b6b;
    font-weight: bold;
  }
  
  .score-summary {
    margin-top: 20px;
    font-size: 1.5rem;
    font-weight: bold;
    color: #ffffff;
  }
  
  .play-again-button {
    background: #f77f00;
    border: none;
    color: #ffffff;
    padding: 15px 30px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1.2rem;
    transition:
      background 0.3s ease,
      transform 0.3s ease;
    margin-top: 20px;
  }
  
  .play-again-button:hover {
    background: #d62839;
    transform: scale(1.05);
  }
  
  @keyframes fadeInResults {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .fill-in-the-blank-questions {
    padding-bottom: 10px;
    font-size: 25px;
  }
  
  .score-summary {
    text-align: center;
    margin-top: 20px;
  }
  
  .highest-score {
    font-size: 1.5rem;
    font-weight: bold;
    color: #f0f0f0; 
    background: linear-gradient(45deg, #1a2b5a, #44c7cc); 
    border-radius: 10px;
    padding: 10px 20px;
    display: inline-block;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); 
    font-family: 'Press Start 2P', cursive; 
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6); 
    margin-bottom: 50px;
  }
  