.practice-message {
  font-family: "Press Start 2P", cursive;
}

.compiler-container {
    height: calc(100vh - 50px); 
    overflow-y: auto;
  }


