.bubble-quiz-container {
    position: relative;
    text-align: center;
  }
  
  .score {
    font-size: 4rem;
    color: #2ecc71;
    font-weight: bold;
    text-shadow: 2px 4px #34495e;
  }
  
  .timer {
    font-size: 1.5rem;
    font-weight: bold;
    color: #e74c3c;
    margin-bottom: 1.5rem;
    animation: timerpulse 1s infinite;
    text-align: center;
  }
  
  .current-question, .timer {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .feedback {
    font-size: 1.5rem;
    color: #fbc531;
    margin-top: 10px;
    animation: bubblefadeIn 0.5s ease-in-out;
  }
  
  .bubble-quiz-area {
    display: grid;
    grid-template-rows: repeat(auto-fit, minmax(140px, 1fr)); 
    grid-auto-flow: column; 
    justify-content: center;
    align-items: center; 
    gap: 15px; 
    height: 40vh; 
    width: 100%; 
    margin: 0 auto; 
}

.level{
  font-size: 1.5rem;
  text-align: center;
  color: #ffdd57;
  text-shadow: 2px 2px #ff6b6b;
}
  
  .bubble {
    width: 140px;
    height: 140px;
    background: linear-gradient(to bottom right, #6DD5FA, #2980b9);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.4);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease;
    cursor: pointer;
    padding: 10px;
    margin: 10px; 
  }
  
  .bubble:hover {
    transform: scale(1.2);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.6);
  }
  
  .bubble.pop {
    transform: scale(0);
    transition: transform 0.5s ease-in-out;
  }
  
  .bubble span {
    white-space: pre-line;
  }
  
  .current-question {
    font-size: 1.5rem;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    word-wrap: break-word; 
    margin-bottom: 15px;
  }
  
  .bubble.pop {
    transform: scale(0);
    transition: transform 0.5s ease-in-out;
  }
  
  .bubble-text {
    pointer-events: none;
  }
  
  .game-over-header {
    font-size: 3.5rem;
    font-weight: bold;
    color: #e84118;
    margin-bottom: 2rem;
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    animation: bounceIn 1s ease;
  }
  
  .game-over-score {
    font-size: 2.2rem;
    margin-bottom: 1.5rem;
    color: #fbc531;
  }
  
  .game-over-buttons button {
    background-color: #44bd32;
    color: #ffffff;
    padding: 0.7rem 2rem;
    font-size: 1.4rem;
    border-radius: 25px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .game-over-buttons button:hover {
    background-color: #40739e;
  }
  
  @keyframes bubblefadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes bounceIn {
    0% {
      transform: scale(0.1);
      opacity: 0;
    }
    60% {
      transform: scale(1.2);
      opacity: 1;
    }
    80% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes timerpulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  