@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

header {
  width: 100%;
  max-width: 100%;
  padding-left: 100px;
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1000;
}

header nav a {
  color: #fff;
  margin-right: 5px;
  padding: 5px 10px;
  font-size: 16px;
  transition: 0.2s;
  text-decoration: none;
}

.carousel {
  width: 100%;
  height: 100vh;
  margin-top: -50px;
  background: linear-gradient(to right, #e2e2e2, #c9d6ff);
  overflow: hidden;
  position: relative;
}

.carousel .list .item {
  width: 150px;
  height: 100px;
  position: absolute;
  top: 93%;
  transform: translateY(-70%);
  left: 70%;
  border-radius: 20px;
  box-shadow: 0 25px 50px rgba(94, 162, 192, 0.5);
  background-position: 50% 50%;
  background-size: cover;
  z-index: 100;
  transition: 1s;
}

.carousel .list .item:nth-child(1),
.carousel .list .item:nth-child(2) {
  top: 0;
  left: 0;
  transform: translate(0, 0);
  border-radius: 0;
  width: 100%;
  height: 100%;
}

.carousel .list .item:nth-child(3) {
  left: 67%;
}

.carousel .list .item:nth-child(4) {
  left: calc(67% + 200px);
}

.carousel .list .item:nth-child(5) {
  left: calc(67% + 400px);
}

.carousel .list .item:nth-child(6) {
  left: calc(67% + 600px);
}

.carousel .list .item:nth-child(n + 7) {
  left: calc(67% + 800px);
  opacity: 0;
}

.list .item .content {
  position: absolute;
  top: 50%;
  left: 100px;
  transform: translateY(-50%);
  width: 400px;
  text-align: left;
  color: #fff;
  display: none;
}

.list .item:nth-child(2) .content {
  display: block;
}

.content .title {
  padding-bottom: 10px;
  font-size: 90px;
  color: #47cec7;
  font-weight: 800;
  line-height: 1;
  animation: animate 1s ease-in-out 0.3s 1 forwards;
  transition: transform 0.2s ease;
}

.content .title,
.content .name,
.content .des {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}

.content .name {
  font-size: 45px;
  font-weight: bold;
  line-height: 1;
  text-shadow: 3px 4px 4px rgba(100, 100, 100, 0.8);
  opacity: 0;
  animation: animate 1s ease-in-out 0.6s 1 forwards;
  padding-bottom: 10px;
}

.content .title:hover {
  animation: jump 0.5s ease;
}

@keyframes jump {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

.content .des1 {
  background-color: rgba(0, 0, 0, 0.6);
  border: solid 1px rgb(96, 216, 210);
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 18px;
  margin-left: 5px;
  padding: 10px;
  color: linear-gradient(90deg, #00cfff, #ffffff);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 10);
  position: relative;
  padding-top: 20px;
  max-height: 200px;
  overflow-y: auto;
}

.content .des1::-webkit-scrollbar {
  width: 8px;
}

.content .des1::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.content .des1::-webkit-scrollbar-thumb {
  background: rgb(96, 216, 210);
  border-radius: 10px;
}

.content .des1::-webkit-scrollbar-thumb:hover {
  background: #00cfff;
}

.content .btn {
  margin-left: 5px;
  opacity: 0;
  animation: animate 1s ease-in-out 1.2s 1 forwards;
}

.content .btn button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border: 2px solid #fff;
}

.content {
  overflow: hidden;
}

.content .btn button:nth-child(1) {
  margin-right: 15px;
}

.content .btn button:nth-child(2) {
  background: transparent;
  color: #14ff72cb;
  border: 2px solid #fff;
  transition: 0.3s;
}

.content .btn button:nth-child(2):hover {
  background-color: #14ff72cb;
  color: #fff;
  border-color: #14ff72cb;
}

@keyframes animate {
  from {
    opacity: 0;
    transform: translate(0, 100px);
    filter: blur(33px);
  }

  to {
    opacity: 1;
    transform: translate(0);
    filter: blur(0);
  }
}

.arrows {
  position: absolute;
  top: 85%;
  right: 40%;
  z-index: 100;
  width: 300px;
  max-width: 30%;
  display: flex;
  gap: 10px;
  align-items: center;
}

.arrows button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #2da894;
  color: #fff;
  border: none;
  outline: none;
  font-size: 16px;
  font-family: monospace;
  font-weight: bold;
  transition: 0.5s;
  cursor: pointer;
}

.arrows button:hover {
  background: #fff;
  color: #000;
}

.carousel .timeRunning {
  position: absolute;
  z-index: 1000;
  width: 0%;
  height: 4px;
  background: linear-gradient(to right, #e2e2e2, #c9d6ff);
  left: 0;
  top: 0;
  animation: runningTime 7s linear 1 forwards;
}

@keyframes runningTime {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@media screen and (max-width: 999px) {
  header {
    padding-left: 50px;
  }

  .list .item .content {
    left: 50px;
  }

  .content .title,
  .content .name {
    font-size: 70px;
  }

  .content .des {
    font-size: 16px;
  }
}

@media screen and (max-width: 690px) {
  header nav a {
    font-size: 14px;
    margin-right: 0;
  }

  .list .item .content {
    top: 40%;
  }

  .content .title,
  .content .name {
    font-size: 45px;
  }

  .content .btn button {
    padding: 10px 15px;
    font-size: 14px;
  }
}

.trivia {
  font-size: 19px;
  font-weight: bold;
  align-items: flex-start;
  background: linear-gradient(90deg, #00cfff, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 4px rgba(51, 51, 51, 0.5);
  display: inline-block;
  border-radius: 10px;
  position: relative;
  text-align: left;
  padding: 10px;
}

.des1 span {
  font-weight: bold;
}

.trivia-text-container {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  max-width: 70%;
  margin-bottom: 20px;
  align-items: center;
}

.trivia span {
  font-weight: bold;
}

.did-you-know {
  position: fixed;
  top: 85px;
  left: 20%;
  transform: translateX(-50%);
  background-color: white;
  color: black;
  font-size: 14px;
  border-radius: 5px;
  width: 130px;
  padding: 5px 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  animation: float 3s ease-in-out infinite;
  text-align: center;
  border: solid 1px rgb(7, 26, 29);
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes float {
  0%,
  100% {
    transform: translateX(-50%) translateY(0);
  }
  50% {
    transform: translateX(-50%) translateY(-5px);
  }
}
