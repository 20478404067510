.user-profile-container {
  padding: 30px;
  background: linear-gradient(to right, #2c3e50, #34495e);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 100%;
  color: white;
  height: 88vh;
}

h2 {
  text-align: center;
  color: #ecf0f1;
  margin-bottom: 20px;
}

.profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #1d9292;
  border-radius: 8px;
  padding: 20px;
  background: #34495e;
  margin-bottom: 20px;
}

.profile-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 15px;
  border: 3px solid #4c6ef5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.user-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.edit-button {
  padding: 10px 20px;
  background: #4c6ef5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.edit-button:hover {
  background: #003d99;
}

.edit-profile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.edit-profile-form {
  background: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  max-height: 80vh;
  color: #333;
  animation: scaleUp 0.3s ease-in-out;
  overflow-y: auto;
}

@keyframes scaleUp {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

.edit-profile-form label {
  display: block;
  margin: 15px 0;
  font-weight: bold;
  color: #333;
}

.edit-profile-form input {
  padding: 12px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 5px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.edit-ok,
.edit-cancel {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.edit-ok {
  background: #4caf50;
  color: white;
  transition: background 0.3s;
}

.edit-cancel {
  background: #f44336;
  color: white;
  transition: background 0.3s;
}

.edit-ok:hover {
  background: #388e3c;
}

.edit-cancel:hover {
  background: #d32f2f;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.profile-picture-preview {
  max-width: 100px;
  max-height: 100px;
  margin-top: 10px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.select-year {
  height: 45px;
  border-radius: 7px;
  width: 100%;
}

.forget-pass-edit {
  border: none;
  width: 100%;
  font-size: 16px;
  background-color: #003d99;
  color: white;
  height: 50px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background 0.3s;
}

.forget-pass-edit:hover {
  background: #113085;
}

.user-details p {
  font-size: 16px;
  display: flex;
  width: 100%;
}

.user-details p span {
    color: #d9dadf;
    flex-grow: 1;
    text-align: left; 
    margin-left: 10px;
}

.user-details strong {
  font-weight: bold;
  width: 100px;
}
