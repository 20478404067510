.FourPic-question-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-height: calc(100vh - 140px);
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  background: linear-gradient(135deg, #1c2b47, #3b4d71);
  border-radius: 15px;
}

.FourPic-container {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  background: transparent;
  border-radius: 12px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
}

.FourPic-question-text {
  font-size: 1.5rem;
  color: #f5f5f5;
  margin-bottom: 20px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6);
}

.FourPic-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.FourPic-images img {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.FourPic-answer-input {
  width: 80%;
  max-width: 300px;
  padding: 10px;
  font-size: 1rem;
  border: 2px solid #ddd;
  border-radius: 6px;
  margin-top: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.FourPic-submit-button,
.FourPic-hint-button {
  padding: 12px 24px;
  font-size: 18px;
  margin: 10px;
  background: #ffdd57;
  border: none;
  color: #1c2b47;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s ease;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.FourPic-submit-button:hover,
.FourPic-hint-button:hover {
  background: #f5a623;
}

.correct {
  border-color: #4caf50;
}

.incorrect {
  border-color: #f44336;
}

.FourPic-answer-boxes {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 20px;
}

.FourPic-answer-box {
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  text-align: center;
  border: 2px solid #ddd;
  border-radius: 6px;
  margin: 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  color: #333;
  background-color: #fff;
  outline: none;
  font-weight: normal;
}

.correct {
  border-color: #4caf50;
}

.incorrect {
  border-color: #f44336;
}

.FourPic-container .modal-header {
  padding-top: 40px;
}

.FourPic-images {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.FourPic-image {
  width: 23%;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.FourPic-game-over-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);
  animation: fadeIn 1s ease-in-out;
}

.FourPic-game-over {
  text-align: center;
  background: rgba(84, 178, 207, 0.5); 
  backdrop-filter: blur(10px);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.4);
  animation: popIn 0.6s ease-out;
  transform: scale(0.95);
}

.FourPic-game-over h2 {
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 20px;
  letter-spacing: 2px;
  text-shadow: 2px 4px 6px rgba(0, 0, 0, 0.3);
}

.FourPic-game-over p {
  font-size: 1.5rem;
  color: #ffffff;
  margin-bottom: 30px;
}

.FourPic-score {
  font-size: 2.5rem;
  color: #ffeaa7;
  font-weight: bold;
  text-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
}

.FourPic-play-again-button {
  padding: 15px 40px;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: #113677;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.FourPic-play-again-button:hover {
  background-color: #2a5298;
  transform: scale(1.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.FourPic-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff6b81;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.FourPic-close-button:hover {
  background-color: #ff4757;
  transform: scale(1.1);
}

.FourPic-close-button:before {
  content: "✖";
  color: #ffffff;
  font-size: 1.2rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes popIn {
  from {
    transform: scale(0.6);
  }
  to {
    transform: scale(1);
  }
}

.FourPic-loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hint-gain-animation {
  position: absolute;
  top: 335px;
  right: 155px;
  font-size: 1.2rem;
  color: #4caf50;
  animation: hintGainAnim 1s ease-in-out forwards;
  pointer-events: none;
}

@keyframes hintGainAnim {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}
