.question-container {
  text-align: center;
  width: 100%;
  padding: 10px;
}

.question-title {
  font-size: 2rem;
  margin-bottom: 20px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  animation: fadeIn 1s ease-out;
}

.options {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.option-button {
  background: #f77f00;
  border: none;
  color: #ffffff;
  padding: 15px 25px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1.2rem;
  transition:
    background 0.3s ease,
    transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.option-button:hover {
  background: #d62839;
  transform: scale(1.05);
}

.results {
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  max-height: calc(100vh - 80px);
  overflow-y: auto;
}

.results ul {
  list-style: none;
  padding: 0;
}

.results li {
  margin: 10px 0;
  padding: 10px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
}

.correct {
  color: #a8d5e2;
  font-weight: bold;
}

.incorrect {
  color: #ff6b6b;
  font-weight: bold;
}

.score-summary {
  margin-top: 20px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
}

.play-again-button {
  background: #f77f00;
  border: none;
  color: #ffffff;
  padding: 15px 30px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1.2rem;
  transition:
    background 0.3s ease,
    transform 0.3s ease;
  margin-top: 20px;
}

.play-again-button:hover {
  background: #d62839;
  transform: scale(1.05);
}

@keyframes fadeInResults {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.multiple-question{
  padding-bottom: 10px;
}
