.tutorial-container {
  background: #fff;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  overflow-y: auto;
}

.tutorial-container h1 {
  color: #005b96;
  font-size: 2.5rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  text-align: start;
  margin-left: 50px;
  font-family: "Roboto", sans-serif;
}

@media (max-width: 1200px) {
  .video-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .video-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .video-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.video-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  justify-content: center;
  text-align: center;
  align-items: start;
}

.video-item {
  position: relative;
  padding: 10px;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  border: none;
  border-radius: 15px;
  justify-content: space-between;
  background-color: transparent;
  overflow: hidden;
  max-height: 400px;
  max-width: 100%;
  margin: 0;
  background-color: transparent;
}

@media (max-width: 768px) {
  .video-container {
    grid-template-columns: 1fr;
  }
}

.video-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
  background-color: #d9f2f5;
  transition: all 0.3s ease;
  border-radius: 20px;
}

.video-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  background-color: #000;
  border-radius: 8px;
  overflow: hidden;
  max-height: 250px;
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.title-video {
  color: #333333;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
  padding: 5px 10px;
  background-color: transparent;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.5; 
  height: 3.5rem; 
}


.play-btn {
  background-color: rgba(255, 255, 255, 0.5);
  color: #0d47a1;
  display: block;
  font-size: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  width: 50px;
  cursor: pointer;
  height: 50px;
  border-radius: 50px;
}

.play-btn:hover {
  background-color: rgba(255, 255, 255, 0.7);
}

.loading-indicator {
  font-size: 1.5rem;
  color: #004d40;
  margin-top: 50px;
}

.skeleton {
  background: #e0e0e0;
  border-radius: 4px;
  width: 100%;
  height: 200px;
  animation: shimmer 1.5s infinite ease-in-out;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.duration {
  position: relative;
  left: 170px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 3px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.fixed-tutorial-header {
  position: fixed;
  top: 70px;
  padding: 20px;
  background-color: none;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 98.8%;
  height: 12vh;
}

.fixed-tutorial-header h1 {
  margin: 0;
}

.search-input {
  width: 300px;
}

.tutorial-container {
  padding-top: 130px;
}
